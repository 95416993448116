<template>
  <v-container fluid>
    <div class="d-flex flex-column justify-center" style="padding: 0 !important; margin: 0 !important">
      <button style="z-index: 9999;     display: flex;
    line-height: 34px;" @click="openWhatsapp" color="inherit" class="btnFloat">
        <v-icon color="white" large>mdi-whatsapp</v-icon>
        <span style="font-size: 18px; font-family: poppins; font-weight: 500;">Olá, gostaria de mais informações sobre o
          Mezzo.</span>
      </button>
    </div>
    <v-row class="bgc1" style="padding: 0px !important;">
      <!-- <v-card v-if="intBreakPoint > 2" class="dia" style="position: absolute; margin: 20vh 0 0 0vw; z-index: 2; padding: 45px 20px 20px 20px">
          <countdown :time="getCountdown()" v-slot="{ days, hours, minutes, seconds }">
            <p style="color: #570c22; text-align: center; font-size: 130px; font-family: Acme !important; line-height: 0.2; font-weight: 800;">{{ days }} <br> <span style="font-size: 70px;"> <br> DIAS </span> <br> 
                <span style="font-size: 28px; margin: 0; padding: 0">{{hours}}h - {{minutes}}m - {{seconds}}s </span> <span style="font-size: 50px"> <br> PARA O </span> <br> <span style="font-size: 53px; color: #f5c515"> BRASIL </span> <br> <span style="font-size: 43px; color: #f5c515">BRILHAR </span>
            </p>
          </countdown>
      </v-card>
      <v-card v-else class="dia" style="position: absolute; margin: 20vh 0 0 0vw; z-index: 2; padding: 45px 20px 20px 20px">
          <countdown :time="getCountdown()" v-slot="{ days, hours, minutes, seconds }">
            <p style="color: #570c22; text-align: center; font-size: 130px; font-family: Acme !important; line-height: 0.2; font-weight: 800;">{{ days }} <br> <span style="font-size: 70px;"> <br> DIAS </span> <br> 
                <span style="font-size: 28px; margin: 0; padding: 0">{{hours}}h - {{minutes}}m - {{seconds}}s </span> <span style="font-size: 50px"> <br> PARA O </span> <br> <span style="font-size: 53px; color: #f5c515"> BRASIL </span> <br> <span style="font-size: 43px; color: #f5c515">BRILHAR </span>
            </p>
          </countdown>
      </v-card> -->
      <v-carousel cycle height="100vh" hide-delimiter-background hide-delimiters :show-arrows="false" interval="3000"
        style="">
        <v-carousel-item v-for="(slide, i) in slides" :key="i">
          <v-img height="100vh" :src="require(`@/assets/${slide}`)" alt="" />F
        </v-carousel-item>
      </v-carousel>

      <!-- <router-link to="/cardapio">
      <v-img
        class="hover"
        width="150"
        style="position: absolute; left: 15px; top: 200px; cursor: pointer"
        src="../assets/menu cardapio.png"
      ></v-img>
      </router-link>
      <router-link to="/localizacao">
      <v-img
        class="hover2"
        width="150"
        style="position: absolute; left: 15px; top: 300px; cursor: pointer"
        src="../assets/localizacao menu.png"
      ></v-img>
      </router-link> -->
    </v-row>

    <v-row>
      <v-col cols="12" md="3" style="cursor: pointer">
        <a href="https://mezzo-bar-delivery.goomer.app/menu#1527550" target="_blank"
          style="text-decoration: none; color: inherit !important" class="d-flex justify-center align-center flex-column">
          <v-img width="140" class="cardapioHover" src="../assets/icone1.png" />
          <h1 style="
            font-family: PeachesFont;
            font-size: 54px;
            font-weight: 500;
            line-height: 20px;
            color: #000;
            ">
            BEBIDAS
          </h1>
        </a>
      </v-col>
      <v-col cols="12" md="3" style="cursor: pointer" class="d-flex justify-center align-center flex-column">
        <a href="https://mezzo-bar-delivery.goomer.app/menu" target="_blank"
          style="text-decoration: none; color: inherit !important;"
          class="d-flex justify-center align-center flex-column">
          <v-img width="140" class="cardapioHover" src="../assets/icones mezzo-05 3.png" />
          <h1 style="
            font-family: PeachesFont;
            font-size: 54px;
            font-weight: 500;
            line-height: 20px;
            color: #B90000;
          ">
            PRATOS
          </h1>
        </a>
      </v-col>

      <v-col cols="12" md="3" style="cursor: pointer" class="d-flex justify-center align-center flex-column">
        <a href="https://mezzo-bar-delivery.goomer.app/menu#1527561" target="_blank"
          style="text-decoration: none; color: inherit !important;"
          class="d-flex justify-center align-center flex-column">
          <v-img width="140" class="cardapioHover" src="../assets/icones mezzo-06.png" />
          <h1 style="
            font-family: PeachesFont;
            font-size: 54px;
            font-weight: 500;
            line-height: 20px;
            margin-top:5px
          ">
            CARTA DE VINHOS
          </h1>
        </a>
      </v-col>

      <v-col cols="12" md="3" style="cursor: pointer" class="d-flex justify-center align-center flex-column">
        <a href="https://mezzo-bar-delivery.goomer.app/menu#1527549" target="_blank"
          style="text-decoration: none; color: inherit !important;"
          class="d-flex justify-center align-center flex-column">
          <v-img width="140" class="cardapioHover" src="../assets/icone4.png" />
          <h1 style="
            font-family: PeachesFont;
            font-size: 54px;
            font-weight: 500;
            line-height: 20px;
          ">
            SOBREMESAS
          </h1>
        </a>
      </v-col>
    </v-row>

    <v-row class="bgcStatic" justify="center" align="center" style="padding: 0 40px">
      <p style="
          text-align: center;
          color: #fff;
          font-family: poppins;
          letter-spacing: 1px;
          font-weight: 300;
          font-size: 1.4pc;
        ">
        Meio bar, meio restaurante. Meio sofisticado, meio simples.
        Completo para você!
      </p>

      <!-- Os melhores sabores e aromas da nossa região com o
        toque Mezzo em todos os detalhes. Gastronomia, drinks e uma vista
        incrível no primeiro mezanino a céu aberto de São José dos Campos. Um
        ambiente feito exclusivamente para você se divertir. -->
    </v-row>

    <v-row class="mt-10" style="background-color: #fff !important">
      <v-col class="conteudo d-flex justify-center align-center flex-column" cols="12" md="3"
        style="padding: 2px 2px !important; max-height: 460px !important">
        <v-img class="image" height="100%" :src="require(`@/assets/${imgs.lasanha}`)">
          <p class="pratosNomes">Lasanha a Bolonhesa</p>
        </v-img>
        <v-icon @click="
          imgDialog = imgs.lasanha;
        mostraDialog = true;
        " style="color: #fff; font-size: 54px" class="middle">mdi-magnify-plus-outline</v-icon>
      </v-col>

      <v-col class="conteudo d-flex justify-center align-center flex-column" cols="12" md="3"
        style="padding: 2px 2px !important; max-height: 460px !important">
        <v-img class="image" height="100%" :src="require(`@/assets/${imgs.img2}`)">
          <p class="pratosNomes">Porção de Pastel</p>
        </v-img>
        <v-icon @click="
          imgDialog = imgs.img2;
        mostraDialog = true;
        " style="color: #fff; font-size: 54px" class="middle">mdi-magnify-plus-outline</v-icon>
      </v-col>

      <v-col class="conteudo d-flex justify-center align-center flex-column" cols="12" md="3"
        style="padding: 2px 2px !important; max-height: 460px !important">
        <v-img class="image" height="100%" :src="require(`@/assets/${imgs.img3}`)">
          <p class="pratosNomes">Torresmo com Limão Cravo</p>
        </v-img>
        <v-icon @click="
          imgDialog = imgs.img3;
        mostraDialog = true;
        " style="color: #fff; font-size: 54px" class="middle">mdi-magnify-plus-outline</v-icon>
      </v-col>

      <v-col class="conteudo d-flex justify-center align-center flex-column" cols="12" md="3"
        style="padding: 2px 2px !important; max-height: 460px !important">
        <v-img class="image" height="100%" :src="require(`@/assets/${imgs.peixe}`)">
          <p class="pratosNomes">Peixe em Crosta de Farinha de Milho</p>
        </v-img>
        <v-icon @click="
          imgDialog = imgs.peixe;
        mostraDialog = true;
        " style="color: #fff; font-size: 54px" class="middle">mdi-magnify-plus-outline</v-icon>
      </v-col>

      <!-- ************************************************************* -->

      <v-col class="conteudo d-flex justify-center align-center flex-column" cols="12" md="3"
        style="padding: 2px 2px !important; max-height: 460px !important">
        <v-img class="image" height="100%" :src="require(`@/assets/${imgs.img4}`)">
          <p class="pratosNomes">Negroni</p>
        </v-img>
        <v-icon @click="
          imgDialog = imgs.img4;
        mostraDialog = true;
        " style="color: #fff; font-size: 54px" class="middle">mdi-magnify-plus-outline</v-icon>
      </v-col>

      <v-col class="conteudo d-flex justify-center align-center flex-column" cols="12" md="3"
        style="padding: 2px 2px !important; max-height: 460px !important">
        <v-img class="image" height="100%" :src="require(`@/assets/${imgs.img5}`)">
          <p class="pratosNomes">Vinhos e Espumantes</p>
        </v-img>
        <v-icon @click="
          imgDialog = imgs.img5;
        mostraDialog = true;
        " style="color: #fff; font-size: 54px" class="middle">mdi-magnify-plus-outline</v-icon>
      </v-col>

      <v-col class="conteudo d-flex justify-center align-center flex-column" cols="12" md="6"
        style="padding: 2px 2px !important; max-height: 460px !important">
        <v-img class="image" height="100%" :src="require(`@/assets/${imgs.img6}`)">
          <p class="pratosNomes">Chopps</p>
        </v-img>
        <v-icon @click="
          imgDialog = imgs.img6;
        mostraDialog = true;
        " style="color: #fff; font-size: 54px" class="middle">mdi-magnify-plus-outline</v-icon>
      </v-col>
    </v-row>

    <!-- <v-row style="background-color:#350110">
      <v-col cols="4" md="4" class="align-center justify-center d-flex" >
        <a href="https://waze.com/ul/h6gz7gq181 " target="_blank">
        <div style="border: 1px solid #fff; border-radius:50px;">
        <v-icon style="color:#fff; font-size:35px !important; padding: 5px 40px">mdi-waze</v-icon>
        </div>
        </a>
      </v-col>
      <v-col cols="4" md="4" class="align-center justify-center d-flex" >
        <a href="-23.2154695,-45.9097991" target="_blank">
        <div style="border: 1px solid #fff; border-radius:50px;">
        <v-icon style="color:#fff; font-size:35px !important; padding: 5px 40px">mdi-google-maps</v-icon>
        </div>
        </a>
      </v-col>
      <v-col cols="4" md="4" class="align-center justify-center d-flex">
        <a href="https://m.uber.com/ul/?action=setPickup&client_id=l1E_iGs-VEDeLoIL4vheuoyUzHG9LNKj&pickup=my_location&dropoff[formatted_address]=Avenida%20Cassiano%20Ricardo%2C%20319%20-%20Loja%2024%20-%20Mezanino%20-%20Jardim%20Aquarius%2C%20S%C3%A3o%20Jos%C3%A9%20dos%20Campos%20-%20SP%2C%2012246-870" target="_blank">
        <div style="border: 1px solid #fff; border-radius:100px;padding: 15px 35px">
        <v-img max-width="50px" max-height="50px" style="" src="../assets/uber.png"></v-img>
        </div>
        </a>
      </v-col>
    </v-row> -->

    <v-row class="mt-10 bgcFooter" justify="center" align="center" style="padding-bottom:40px">
      <v-col cols="12" md="6" class="d-flex justify-center flex-column" style="padding: 20px 40px">
        <h2 style="color: #fff; margin-bottom:10px; font-family: Poppins; font-weight: 500">
          Horário de Funcionamento
        </h2>
        <!-- <v-img src="../assets/HorarioFuncionamentoSite.png" contain /> -->
        <v-row class="rowHorario text-center">
          <v-col cols="2" md="2"></v-col>
          <v-col cols="5" md="5" class="yellow"><b>1º Horário</b></v-col>
          <v-col cols="5" md="5" class="yellow"><b>2º Horário</b></v-col>
        </v-row>
        <!-- terça feira -->
        <v-row class="rowHorario text-center">
          <v-col cols="2" md="2" class="yellow" style="text-align: left;">{{ getDay(0) }}</v-col>
          <v-col cols="5" md="5">12:00h - 14:30h</v-col>
          <v-col cols="5" md="5">17:00h - 23:00h</v-col>
        </v-row>
        <v-row class="rowHorario text-center">
          <v-col cols="2" md="2" class="yellow" style="text-align: left;">{{ getDay(1) }}</v-col>
          <v-col cols="5" md="5">12:00h - 14:30h</v-col>
          <v-col cols="5" md="5">17:00h - 23:00h</v-col>
        </v-row>
        <v-row class="rowHorario text-center">
          <v-col cols="2" md="2" class="yellow" style="text-align: left;">{{ getDay(2) }}</v-col>
          <v-col cols="5" md="5">12:00h - 14:30h</v-col>
          <v-col cols="5" md="5">17:00h - 23:00h</v-col>
        </v-row>
        <v-row class="rowHorario text-center">
          <v-col cols="2" md="2" class="yellow" style="text-align: left;">{{ getDay(3) }}</v-col>
          <v-col cols="5" md="5">12:00h - 14:30h</v-col>
          <v-col cols="5" md="5">17:00h - 00:00h</v-col>
        </v-row>
        <v-row class="rowHorario text-center">
          <v-col cols="2" md="2" class="yellow" style="text-align: left;">{{ getDay(4) }}</v-col>
          <v-col cols="5" md="5">12:00h - 00:00h</v-col>
          <v-col></v-col>
        </v-row>
        <v-row class="rowHorario text-center">
          <v-col cols="2" md="2" class="yellow" style="text-align: left;">{{ getDay(5) }}</v-col>
          <v-col cols="5" md="5"> 12:00h - 17:00h</v-col>
          <v-col></v-col>
        </v-row>
        <v-row class="mt-10" style="padding-top:40px">
          <h3 class="text-center" style="color: #fff; font-family: Poppins; font-weight: 300">
            Telefone: <a href="tel:012996412263">(12) 99641-2263</a>
          </h3>
        </v-row>
        <v-row>
          <p class="text-center">
            <v-icon @click="openFb" color="white" x-large class="mr-3">mdi-facebook</v-icon>
            <v-icon @click="openIg" color="white" x-large class="mr-3">mdi-instagram</v-icon>
            <v-icon @click="openWhatsapp" color="white" x-large class="mr-3">mdi-whatsapp</v-icon>
          </p>
        </v-row>
      </v-col>

      <v-col cols="12" md="6" style="padding: 20px 60px;">
        <h3 style="font-family: poppins; font-weight: 400; color: #fff">Críticas e sugestões</h3>
        <v-form ref="form" id="contato" lazy-validation dark>
          <v-text-field color="#8e1f3f" dark v-model="contato.nome" :counter="60" label="Nome" required></v-text-field>

          <v-text-field color="#8e1f3f" dark v-model="contato.email" label="E-mail" required></v-text-field>

          <v-text-field v-mask="'(##) #####-####'" v-model="contato.telefone" dark :counter="14" label="Telefone" required
            color="#8e1f3f"></v-text-field>

          <v-textarea dark color="#8e1f3f" v-model="contato.mensagem" :counter="7" label="Mensagem" required></v-textarea>

          <v-btn block @click="enviarFormulario" class="mr-4 align-center justify-center d-flex"
            style="color: #8e1f3f; font-family: Poppins">
            Enviar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>

    <v-row style="background-color: #000;">
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <p style="font-size: 12px; color: #fff; text-align: left; margin-bottom: 0px;">MEZZO BAR E RESTAURANTE 2021 –
          Todos direitos reservados.</p>
      </v-col>

      <v-col cols="12" md="4" class="d-flex justify-center align-center">

        <v-btn @click="politica = true" style="font-size: 12px; color: #fff; text-align: center; margin-bottom: 0px;     background-color: transparent;
    color: #fff !important;
    font-family: poppins;">
          Politica de privacidade
        </v-btn>
      </v-col>

      <v-col cols="12" md="4" class="d-flex justify-center align-center flex-column">
        <v-img style="width: 40px; height: 40px;" src="../assets/abareBranco.png"></v-img>
      </v-col>
    </v-row>

    <v-dialog v-model="politica" width="100%" class="d-flex justify-center flex-column"
      style=" background-color: #fff !important;">
      <h2>Política Privacidade</h2>
      <p>A sua privacidade é importante para nós. É política do Mezzo Bar respeitar a sua privacidade em relação a
        qualquer informação sua que possamos coletar no site <a href="https://www.mezzobar.com.br">Mezzo Bar</a>, e outros
        sites que possuímos e operamos.</p>
      <p>Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. Fazemo-lo
        por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que estamos coletando e
        como será usado. </p>
      <p>Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. Quando
        armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e roubos, bem como
        acesso, divulgação, cópia, uso ou modificação não autorizados.</p>
      <p>Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando exigido por
        lei.</p>
      <p>O nosso site pode ter links para sites externos que não são operados por nós. Esteja ciente de que não temos
        controle sobre o conteúdo e práticas desses sites e não podemos aceitar responsabilidade por suas respectivas <a
          href='https://politicaprivacidade.com' target='_BLANK'>políticas de privacidade</a>. </p>
      <p>Você é livre para recusar a nossa solicitação de informações pessoais, entendendo que talvez não possamos
        fornecer alguns dos serviços desejados.</p>
      <p>O uso continuado de nosso site será considerado como aceitação de nossas práticas em torno de privacidade e
        informações pessoais. Se você tiver alguma dúvida sobre como lidamos com dados do usuário e informações pessoais,
        entre em contacto connosco.</p>
      <h2>Política de Cookies Mezzo Bar</h2>
      <h3>O que são cookies?</h3>
      <p>Como é prática comum em quase todos os sites profissionais, este site usa cookies, que são pequenos arquivos
        baixados no seu computador, para melhorar sua experiência. Esta página descreve quais informações eles coletam,
        como as usamos e por que às vezes precisamos armazenar esses cookies. Também compartilharemos como você pode
        impedir que esses cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou 'quebrar' certos elementos
        da funcionalidade do site.</p>
      <h3>Como usamos os cookies?</h3>
      <p>Utilizamos cookies por vários motivos, detalhados abaixo. Infelizmente, na maioria dos casos, não existem opções
        padrão do setor para desativar os cookies sem desativar completamente a funcionalidade e os recursos que eles
        adicionam a este site. É recomendável que você deixe todos os cookies se não tiver certeza se precisa ou não
        deles, caso sejam usados para fornecer um serviço que você usa.</p>
      <h3>Desativar cookies</h3>
      <p>Você pode impedir a configuração de cookies ajustando as configurações do seu navegador (consulte a Ajuda do
        navegador para saber como fazer isso). Esteja ciente de que a desativação de cookies afetará a funcionalidade
        deste e de muitos outros sites que você visita. A desativação de cookies geralmente resultará na desativação de
        determinadas funcionalidades e recursos deste site. Portanto, é recomendável que você não desative os cookies.</p>
      <h3>Cookies que definimos</h3>
      <ul>
        <li> Cookies relacionados à conta<br><br> Se você criar uma conta connosco, usaremos cookies para o gerenciamento
          do processo de inscrição e administração geral. Esses cookies geralmente serão excluídos quando você sair do
          sistema, porém, em alguns casos, eles poderão permanecer posteriormente para lembrar as preferências do seu site
          ao sair.<br><br> </li>
        <li> Cookies relacionados ao login<br><br> Utilizamos cookies quando você está logado, para que possamos lembrar
          dessa ação. Isso evita que você precise fazer login sempre que visitar uma nova página. Esses cookies são
          normalmente removidos ou limpos quando você efetua logout para garantir que você possa acessar apenas a recursos
          e áreas restritas ao efetuar login.<br><br> </li>
        <li> Cookies relacionados a boletins por e-mail<br><br> Este site oferece serviços de assinatura de boletim
          informativo ou e-mail e os cookies podem ser usados para lembrar se você já está registrado e se deve mostrar
          determinadas notificações válidas apenas para usuários inscritos / não inscritos.<br><br> </li>
        <li> Pedidos processando cookies relacionados<br><br> Este site oferece facilidades de comércio eletrônico ou
          pagamento e alguns cookies são essenciais para garantir que seu pedido seja lembrado entre as páginas, para que
          possamos processá-lo adequadamente.<br><br> </li>
        <li> Cookies relacionados a pesquisas<br><br> Periodicamente, oferecemos pesquisas e questionários para fornecer
          informações interessantes, ferramentas úteis ou para entender nossa base de usuários com mais precisão. Essas
          pesquisas podem usar cookies para lembrar quem já participou numa pesquisa ou para fornecer resultados precisos
          após a alteração das páginas.<br><br> </li>
        <li> Cookies relacionados a formulários<br><br> Quando você envia dados por meio de um formulário como os
          encontrados nas páginas de contacto ou nos formulários de comentários, os cookies podem ser configurados para
          lembrar os detalhes do usuário para correspondência futura.<br><br> </li>
        <li> Cookies de preferências do site<br><br> Para proporcionar uma ótima experiência neste site, fornecemos a
          funcionalidade para definir suas preferências de como esse site é executado quando você o usa. Para lembrar suas
          preferências, precisamos definir cookies para que essas informações possam ser chamadas sempre que você
          interagir com uma página for afetada por suas preferências.<br> </li>
      </ul>
      <h3>Cookies de Terceiros</h3>
      <p>Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a seguir detalha
        quais cookies de terceiros você pode encontrar através deste site.</p>
      <ul>
        <li> Este site usa o Google Analytics, que é uma das soluções de análise mais difundidas e confiáveis da Web, para
          nos ajudar a entender como você usa o site e como podemos melhorar sua experiência. Esses cookies podem rastrear
          itens como quanto tempo você gasta no site e as páginas visitadas, para que possamos continuar produzindo
          conteúdo atraente. </li>
      </ul>
      <p>Para mais informações sobre cookies do Google Analytics, consulte a página oficial do Google Analytics.</p>
      <ul>
        <li> As análises de terceiros são usadas para rastrear e medir o uso deste site, para que possamos continuar
          produzindo conteúdo atrativo. Esses cookies podem rastrear itens como o tempo que você passa no site ou as
          páginas visitadas, o que nos ajuda a entender como podemos melhorar o site para você.</li>
        <li> Periodicamente, testamos novos recursos e fazemos alterações subtis na maneira como o site se apresenta.
          Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que você receba uma
          experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais
          apreciam.</li>
        <li> À medida que vendemos produtos, é importante entendermos as estatísticas sobre quantos visitantes de nosso
          site realmente compram e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso é importante para
          você, pois significa que podemos fazer previsões de negócios com precisão que nos permitem analizar nossos
          custos de publicidade e produtos para garantir o melhor preço possível.</li>
        <li> O serviço Google AdSense que usamos para veicular publicidade usa um cookie DoubleClick para veicular
          anúncios mais relevantes em toda a Web e limitar o número de vezes que um determinado anúncio é exibido para
          você.<br> Para mais informações sobre o Google AdSense, consulte as FAQs oficiais sobre privacidade do Google
          AdSense. </li>
        <li> Utilizamos anúncios para compensar os custos de funcionamento deste site e fornecer financiamento para
          futuros desenvolvimentos. Os cookies de publicidade comportamental usados por este site foram projetados para
          garantir que você forneça os anúncios mais relevantes sempre que possível, rastreando anonimamente seus
          interesses e apresentando coisas semelhantes que possam ser do seu interesse.</li>
        <li>Vários parceiros anunciam em nosso nome e os cookies de rastreamento de afiliados simplesmente nos permitem
          ver se nossos clientes acessaram o site através de um dos sites de nossos parceiros, para que possamos
          creditá-los adequadamente e, quando aplicável, permitir que nossos parceiros afiliados ofereçam qualquer
          promoção que pode fornecê-lo para fazer uma compra. </li>
      </ul>
      <h3>Compromisso do Usuário</h3>
      <p>O usuário se compromete a fazer uso adequado dos conteúdos e da informação que o Mezzo Bar oferece no site e com
        caráter enunciativo, mas não limitativo:</p>
      <ul>
        <li>A) Não se envolver em atividades que sejam ilegais ou contrárias à boa fé a à ordem pública;</li>
        <li>B) Não difundir propaganda ou conteúdo de natureza racista, xenofóbica, <a
            style='color: inherit !important; text-decoration:none !important;' href='https://jogoshoje.io'>jogos de
            hoje</a> ou azar, qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou contra os direitos
          humanos;</li>
        <li>C) Não causar danos aos sistemas físicos (hardwares) e lógicos (softwares) do Mezzo Bar, de seus fornecedores
          ou terceiros, para introduzir ou disseminar vírus informáticos ou quaisquer outros sistemas de hardware ou
          software que sejam capazes de causar danos anteriormente mencionados.</li>
      </ul>
      <h3>Mais informações</h3>
      <p>Esperemos que esteja esclarecido e, como mencionado anteriormente, se houver algo que você não tem certeza se
        precisa ou não, geralmente é mais seguro deixar os cookies ativados, caso interaja com um dos recursos que você
        usa em nosso site.</p>
      <p>Esta política é efetiva a partir de <strong>January</strong>/<strong>2022</strong>.</p>

    </v-dialog>


    <v-dialog v-model="imgDialog" width="100%" class="d-flex justify-center flex-column">
      <v-card>
        <v-img contain height="" v-if="imgDialog != ''" :src="require(`@/assets/${imgDialog}`)"></v-img>
        <!-- <v-card-actions class="justify-end">
          <v-btn text @click="imgDialog = false">Fechar</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<style scoped>
.dia {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.596) !important;
}

.cardapioHover:hover {
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 66%);
  border-radius: 40px;
  margin: 0px 0px 30px 0px;
}

@media (min-device-width: 360px) and (max-device-width: 730px) {
  .v-dialog {
    padding: 10px !important;
  }
}

.rowHorario {
  color: white;
}

.bgcFooter {
  /* background: #952041; */
  background: linear-gradient(180deg,
      rgba(52, 0, 15, 1) 0%,
      rgba(142, 31, 63, 1) 100%);
}

.yellow {
  color: #ffc155 !important;
  background-color: transparent !important;
  font-weight: bold;
}

a {
  color: white !important;
}

.pratosNomes {
  position: absolute;
  font-size: 25px;
  color: rgb(255, 255, 255);
  bottom: 10px;
  left: 20px;
  text-shadow: rgb(0 0 0 / 82%) 0px 2px 3px;
  font-family: poppins;
}

.conteudo {
  position: relative;
  width: 50%;
  background-color: #000;
}

.bgcImgColor {
  background-color: #000;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  /* height: auto; */
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.conteudo:hover .image {
  opacity: 0.3;
  background-color: #000 !important;
}

.conteudo:hover .middle {
  opacity: 1;
}

.text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

/* ****************** */

.bgcStatic {
  background-image: url("../assets/bannerStatic.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 280px;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.hover:hover {
  width: 160px !important;
}

.hover2:hover {
  width: 160px !important;
}

.v-application .mt-10 {
  margin-top: 0px !important;
}

.btnFloat {
  position: fixed !important;
  right: -421px;
  font-size: 12px !important;
  border-radius: 20px 0 0 20px !important;
  background-color: rgb(18 211 136 / 90%) !important;
  color: #ffffff !important;
  font-family: "Poppins" !important;
  top: 300px !important;
  padding: 7px 10px;
  font-weight: 300 !important;
}

.btnFloat:hover {
  right: -1px !important;
  transition: all 0.3s ease !important;
}

.cardAniversario {
  background-color: #9d2b4b !important;
  height: fit-content;
  width: 100%;
}

.btnOk {
  background-color: #fff;
  color: #7a1e1e;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 500;
}
</style>

<script>
export default {
  data: () => ({
    timeout: 2000,
    snackbar: false,
    text: "Seu contato foi enviado! Obriado por sua mensagem, responderemos em breve.",
    imgDialog: "",
    politica: false,
    days: ["TERÇA", "QUARTA", "QUINTA", "SEXTA", "SÁBADO", "DOMINGO"],
    mobileDays: ["TER", "QUA", "QUI", "SEX", "SÁB", "DOM"],
    contato: { nome: "", email: "", mensagem: "", telefone: "" },
    slides: [
      // "banner7.webp",
      "banner1.webp",
      "banner2.webp",
      "banner3.webp",
      "banner4.webp",
      "banner5.webp",
      // "banner6.webp",
      "banner8.webp",
      "banner9.webp",
    ],

    imgs: {
      // img1: "galeria1.webp",
      lasanha: "lasanha.webp",
      peixe: "peixe.webp",
      img2: "galeria2.webp",
      img3: "galeria3.webp",
      img4: "galeria4.webp",
      img5: "vinhos.webp",
      img6: "chops.webp",
    },
    aniversario: true,
  }),
  computed: {
    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 1;
        case "md":
          return 2;
        case "lg":
          return 3;
        case "xl":
          return 4;
        default:
          return 0;
      }
    },
  },
  methods: {
    getDay(intDay) {
      if (this.intBreakPoint > 1) {
        return this.days[intDay];
      } else {
        return this.mobileDays[intDay];
      }
    },
    enviarFormulario() {
      fetch("https://gestao.abare.cloud/items/leads", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id_formulario: 36, lead: this.contato }),
      })
        .then((response) => response.text())
        .then((data) => {
          window.console.log(data);
          // alert("contato enviado com sucesso");
          this.dialog = false;
          this.snackbar = true;
        });
    },

    openIg() {
      window.open("https://www.instagram.com/mezzobarsjc/", "_blank");
    },
    openFb() {
      window.open("https://www.facebook.com/mezzobarsjc/", "_blank");
    },
    openWhatsapp() {
      let strMsg =
        "Ola gostária de saber mais informações sobre o Mezzo Bar";
      window.open(
        "https://api.whatsapp.com/send?phone=+5512996412263&text=" +
        strMsg,
        "_blank"
      );
    },
    downloadCardapio() {
      window.open("https://mezzobar.com.br/media/Menuv9.pdf", "_blank");
    },

    downloadBebidas() {
      window.open(
        "https://mezzobar.com.br/media/bebidas.pdf",
        "_blank"
      );
    },

    downloadSobremeza() {
      window.open(
        "https://mezzobar.com.br/media/sobremezas.pdf",
        "_blank"
      );
    },

    downloadVinhos() {
      window.open(
        "https://mezzobar.com.br/media/vinhos.pdf",
        "_blank"
      );
    },

    downloadCardapioExe() {
      window.open(
        "https://mezzobar.com.br/media/pratos.pdf",
        "_blank"
      );
    },
    getCountdown() {
      let now = new Date();
      const diaCopa = new Date("2022-12-05 16:00:00");

      let countdown = diaCopa - now;

      // console.log(countdown)
      return countdown;
    },
  },
};
</script>