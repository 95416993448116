<template>
  <v-app>
    <notifications
      :duration="2000"
      width="40%"
      position="center center"
      group="foo"
      style="padding-top: 8%; pointer-events: none"
    />

    <v-app-bar v-if="!isLandingPage" app dark class="mezzoAppBar">
      <v-row class="align-center" style="background-color: #570c22">
        <v-col
          class="d-flex align-center justify-center flex-column"
          cols="6"
          md="3"
          style="padding-bottom: 0"
        >
          <router-link to="/">
            <v-img
              class="shrink mr-2"
              contain
              src="./assets/logo.png"
              transition="scale-transition"
              width="80"
            />
          </router-link>
        </v-col>

        <v-col
          v-if="intBreakPoint >= 2"
          cols="6"
          class="d-flex justify-center align-center"
          style="padding-bottom: 0"
        >
          <a
            target="_blank"
            style="text-decoration: none"
            href="https://gqr.be/0PAo3"
          >
            <v-list-item
              style="
                color: #fff;
                font-size: 21px;
                font-family: poppins !important;
              "
            >
              <v-icon color="white" style="margin-right: 10px !important"
                >mdi-book-open-page-variant-outline</v-icon
              >
              Cardápio
            </v-list-item>
          </a>
          <a
            style="text-decoration: none"
            href="https://mezzobar.com.br/localizacao"
          >
            <v-list-item
              style="
                color: #fff;
                font-size: 21px;
                font-family: poppins !important;
              "
            >
              <v-icon color="white" style="margin-right: 10px !important"
                >mdi-map-marker-radius</v-icon
              >
              Localização
            </v-list-item>
          </a>
          <router-link to="/clubemezzo">
            <v-list-item
              style="
                color: #fff;
                font-size: 21px;
                font-family: poppins !important;
                padding: 20px 10px;
              "
            >
              <v-icon color="white" style="margin-right: 10px !important"
                >mdi-ticket-percent</v-icon
              >
              Clube Mezzo
            </v-list-item>
          </router-link>
          <router-link to="/eventos">
            <v-list-item
              style="
                color: #fff;
                font-size: 21px;
                font-family: poppins !important;
                padding: 20px 10px;
              "
            >
              <v-icon color="white" style="margin-right: 10px !important"
                >mdi-glass-flute</v-icon
              >
              Eventos & Confras
            </v-list-item>
          </router-link>
        </v-col>

        <v-col
          cols="6"
          v-if="intBreakPoint < 2"
          class="align-center justify-center d-flex"
        >
          <v-btn class="menuMobileNone" dark icon>
            <v-icon
              v-if="intBreakPoint < 2"
              @click.stop="drawer = !drawer"
              style="color: #fff"
              >mdi-menu</v-icon
            >
          </v-btn>
        </v-col>

        <v-col
          v-if="intBreakPoint >= 2"
          cols="3"
          class="d-flex justify-center align-center"
          style="padding-top: 25px; padding-bottom: 0"
        >
          <p class="text-center mobileSociais">
            <v-icon @click="openFb" color="white" x-large class="mr-3"
              >mdi-facebook</v-icon
            >
            <v-icon @click="openIg" color="white" x-large class="mr-3"
              >mdi-instagram</v-icon
            >
            <v-icon
              v-if="$route.path === '/perfil' || $route.path === '/panel'"
              size="2.5rem"
              @click="logout"
              >mdi-logout</v-icon
            >
          </p>
        </v-col>
        <!-- <div
          v-show="indicativo"
          style="position: fixed; top: 50px; left: 46px"
          class="indicativo"
        >
          <v-icon @click="indicativo = !indicativo" class="closeIcon"
            >mdi-close</v-icon
          >
          <v-img width="150" height="80" src="./assets/indicativo.png"></v-img>
        </div> -->
      </v-row>
    </v-app-bar>

    <!-- ********************************************* -->
    <!-- ********************************************* -->

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      left
      style="background-color: rgb(189 16 16 / 85%); opacity: 0.9"
    >
      <v-spacer></v-spacer>
      <v-list dense>
        <!-- <a
					style="text-decoration: none"
					href="https://mezzo-bar-e-restaurante.cluvi.com.br/mezzo-bar-e-restaurante/menu-digital/home"
				> -->
        <v-list-item
          @click="cardapioMobile = true"
          style="
            color: #fff;
            font-size: 21px;
            font-family: poppins !important;
            margin-top: 20px;
            padding: 20px 10px;
          "
        >
          <v-icon color="white" style="margin-right: 10px !important"
            >mdi-book-open-page-variant-outline</v-icon
          >
          Cardápio
        </v-list-item>
        <!-- </a> -->

        <a
          style="text-decoration: none"
          href="https://mezzobar.com.br/localizacao"
        >
          <v-list-item
            style="
              color: #fff;
              font-size: 21px;
              font-family: poppins !important;
              padding: 20px 10px;
            "
          >
            <v-icon color="white" style="margin-right: 10px !important"
              >mdi-map-marker-radius</v-icon
            >
            Localização
          </v-list-item>
        </a>

        <router-link to="/clubemezzo">
          <v-list-item
            style="
              color: #fff;
              font-size: 21px;
              font-family: poppins !important;
              padding: 20px 10px;
            "
          >
            <v-icon color="white" style="margin-right: 10px !important"
              >mdi-ticket-percent</v-icon
            >
            Clube Mezzo
          </v-list-item>
        </router-link>
        <router-link to="/eventos">
          <v-list-item
            style="
              color: #fff;
              font-size: 21px;
              font-family: poppins !important;
              padding: 20px 10px;
            "
          >
            <v-icon color="white" style="margin-right: 10px !important"
              >mdi-glass-flute</v-icon
            >
            Eventos & Confras
          </v-list-item>
        </router-link>
        <v-list-item
          style="
            color: #fff;
            font-size: 21px;
            font-family: poppins !important;
            padding: 20px 10px;
          "
        >
          <v-icon
            v-if="$route.path === '/perfil' || $route.path === '/panel'"
            size="2.5rem"
            color="#fff"
            @click="logout"
            >mdi-logout</v-icon
          >
          Logout
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- ********************************************* -->
    <!-- ********************************************* -->

    <!-- <v-row
      v-if="showMenuMob"
      style="
        display: grid;
        position: fixed;
        top: 200px;
        z-index: 99;
        left: 20px;
      "
    >
      <router-link to="/cardapio">
        <v-img
          class="hover"
          width="150"
          style="cursor: pointer"
          src="./assets/menu cardapio2.png"
        ></v-img>
      </router-link>
      <router-link to="/localizacao">
        <v-img
          class="hover2"
          width="150"
          style="cursor: pointer"
          src="./assets/localizacao menu2.png"
        ></v-img>
      </router-link>
      
      <router-link to="/clube-mezzo">
        <v-img
          width="150"
          style="cursor: pointer"
          src="./assets/clubeMezzo.png"
        />
      </router-link>

      <router-link to="/empresas-parceiras">
        <v-img
          width="150"
          style="cursor: pointer"
          src="./assets/empresasMenu.png"
        />
      </router-link>
    </v-row> -->
    <!-- 
    <v-row class="mobileMenu" v-if="!isMembers" style="
        display: grid;
        position: absolute;
        top: 200px;
        z-index: 99;
        left: 20px;
      ">
      <a style="text-decoration: none;"
        href="https://mezzo-bar-e-restaurante.cluvi.com.br/mezzo-bar-e-restaurante/menu-digital/home">
        <v-img class="hover" width="150" style="cursor: pointer" src="./assets/menu cardapio.png"></v-img>
      </a>
      <router-link to="/localizacao">
        <v-img class="hover2" width="150" style="cursor: pointer" src="./assets/localizacao menu.png"></v-img>
      </router-link> -->
    <!-- <router-link to="/clube-mezzo">
        <v-img
          width="150"
          style="cursor: pointer"
          src="./assets/clubeMezzo.png"
        />
      </router-link> -->

    <!-- <router-link to="/empresas-parceiras">
        <v-img width="150" style="cursor: pointer" src="./assets/empresasMenu.png" />
      </router-link>
    </v-row> -->

    <v-main class="pt-0">
      <router-view />
    </v-main>

    <v-dialog v-model="cardapioMobile">
      <v-row>
        <v-col>
          <v-row>
            <v-col
              cols="12"
              md="3"
              style="cursor: pointer"
              class="d-flex justify-center align-center flex-column"
            >
              <a
                href="https://mezzo-bar-delivery.goomer.app/menu#1527550"
                target="_blank"
                style="text-decoration: none; color: #000 !important"
                class="d-flex justify-center align-center flex-column"
              >
                <v-img
                  width="140"
                  class="cardapioHover"
                  src="./assets/icone1.png"
                />
                <h1
                  style="
                    font-family: PeachesFont;
                    font-size: 54px;
                    font-weight: 500;
                    line-height: 20px;
                  "
                >
                  BEBIDAS
                </h1>
              </a>
            </v-col>
            <v-col
              cols="12"
              md="3"
              style="cursor: pointer"
              class="d-flex justify-center align-center flex-column"
            >
              <a
                href="https://mezzo-bar-delivery.goomer.app/menu"
                target="_blank"
                style="text-decoration: none; color: inherit !important"
                class="d-flex justify-center align-center flex-column"
              >
                <v-img
                  width="140"
                  class="cardapioHover"
                  src="./assets/icones mezzo-05 3.png"
                />
                <h1
                  style="
									font-family: PeachesFont;
									font-size: 54px;
									font-weight: 500;
									line-height: 20px;members
									color: #B90000;
									"
                >
                  PRATOS
                </h1>
              </a>
            </v-col>

            <v-col
              cols="12"
              md="3"
              style="cursor: pointer"
              class="d-flex justify-center align-center flex-column"
            >
              <a
                href="https://mezzo-bar-delivery.goomer.app/menu#1527561"
                target="_blank"
                style="text-decoration: none; color: inherit !important"
                class="d-flex justify-center align-center flex-column"
              >
                <v-img
                  width="140"
                  class="cardapioHover"
                  src="./assets/icones mezzo-06.png"
                />
                <h1
                  style="
                    font-family: PeachesFont;
                    font-size: 54px;
                    font-weight: 500;
                    line-height: 20px;
                    margin-top: 5px;
                  "
                >
                  CARTA DE VINHOS
                </h1>
              </a>
            </v-col>

            <v-col
              cols="12"
              md="3"
              style="cursor: pointer"
              class="d-flex justify-center align-center flex-column"
            >
              <a
                href="https://mezzo-bar-delivery.goomer.app/menu#1527549"
                target="_blank"
                style="text-decoration: none; color: inherit !important"
                class="d-flex justify-center align-center flex-column"
              >
                <v-img
                  width="140"
                  class="cardapioHover"
                  src="./assets/icone4.png"
                />
                <h1
                  style="
                    font-family: PeachesFont;
                    font-size: 54px;
                    font-weight: 500;
                    line-height: 20px;
                  "
                >
                  SOBREMESAS
                </h1>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-dialog>

    <v-dialog v-model="dialog">
      <div style="padding: 10px !important">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Privacy Policy
          </v-card-title>

          <v-card-text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              I accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <!-- 
    <v-dialog v-model="aviso"
    style="background-color: transparent !important; padding:0px !important"
      max-width="520px"
      margin="0px">
      <v-card style="background-color:#871D3B; padding: 20px 0;">
          <v-btn color="primary" style="left:450px; top:20px" text @click="aviso = false"> 
          <v-icon style="color:#fff; "> mdi-close </v-icon> </v-btn>
  

        <v-card-title class="text-h5 lighten-2  justify-center align-center" style= "color:#fff; font-family: Poppins">
          Dia dos namorados
        </v-card-title>

        <v-card-text class="align-center justify-center" style="color: #fff; font-family: Poppins; text-align: center;">
          Dia dos Namorados no Mezzo
          <br>
          Dia 12/06 - Domingo
           <br>
          Estaremos abertos das 12hs às 23hs
           <br>
          Confira os pratos especiais no Instagram @mezzobarsjc
        </v-card-text>

        
      </v-card>
    </v-dialog> -->
  </v-app>
</template>

<style>
.config-dialog {
  width: 30% !important;
  height: 30vh;
  overflow: hidden;
}

.vue-notification {
  font-size: 20px !important;
}

.v-toolbar__content {
  padding: 0px !important;
}

.closeIcon {
  float: right;
  font-size: 25px;
  color: #f00 !important;
  background-color: #ffffff52;
  border-radius: 20px;
  padding: 5px;
}

a {
  text-decoration: none !important;
  color: none !important;
}

.v-dialog {
  padding: 0px !important;
  background: transparent !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c58e9e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8b1e3d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.menuMobileNone {
  display: none !important;
}

.indicativo {
  display: none;
}

@media (min-device-width: 360px) and (max-device-width: 731px) {
  .indicativo {
    display: initial;
  }

  .mobileSociais {
    display: none !important;
  }

  .mobileMenu {
    display: none !important;
  }

  .menuMobileNone {
    display: inherit !important;
  }
}

.v-step__button {
  background-color: #7a1e1e;
  padding: 3px 15px;
  color: #fff !important;
  float: right;
  margin-top: 20px;
}

.v-dialog {
  border-radius: 4px;
  background: #fff !important;
  padding: 60px;
}

.mezzoAppBar {
  box-shadow: none !important;
  background: linear-gradient(
    0deg,
    rgba(3, 6, 87, 0) 0%,
    rgba(0, 0, 0, 0.742734593837535) 100%
  ) !important;
}

@font-face {
  font-family: 'PeachesFont';
  src: url('./assets/fonts/PeachesFont.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'DancingScript';
  src: url('./assets/fonts/DancingScript.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'exo2';
  src: url('./assets/fonts/Exo2-Light.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: 'exo2';
  src: url('./assets/fonts/Exo2-Regular.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: 'exo2';
  src: url('./assets/fonts/Exo2-Medium.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: 'exo2';
  src: url('./assets/fonts/Exo2-ExtraBold.ttf') format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
</style>

<script>
export default {
  name: 'App',

  data: () => ({
    drawer: false,
    dialog: false,
    aviso: true,
    cardapioMobile: false,
    showMenuMob: false,
    indicativo: true
    // items: [{ title: "menu cardapio.png" }, { title1: "menu cardapio2.png" }],
    //
  }),

  computed: {
    isLandingPage() {
      let lps = ['Harmonizza', 'Propagandistas', 'Aniversário 2 anos'];
      return lps.indexOf(this.$route.name) > -1;
    },

    isMembers() {
      return (
        this.$route.name === 'ShowMembers' ||
        this.$route.name === 'ShowNPSMembers'
      );
    },

    isCopa() {
      return this.$route.name == 'lpCopa';
    },
    isNatal() {
      return this.$route.name == 'lpNatal';
    },
    isAniversario() {
      return this.$route.name == 'Aniversario 2 anos';
    },
    currentRouteName() {
      return this.$route.name;
    },

    intBreakPoint() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 0;
        case 'sm':
          return 1;
        case 'md':
          return 2;
        case 'lg':
          return 3;
        case 'xl':
          return 4;
        default:
          return 0;
      }
    }
  },

  methods: {
    openIg() {
      window.open('https://www.instagram.com/mezzobarsjc/', '_blank');
    },
    openFb() {
      window.open('https://www.facebook.com/mezzobarsjc/', '_blank');
    },

    downloadBebidas() {
      window.open('https://mezzobar.com.br/media/bebidas.pdf', '_blank');
    },

    downloadSobremeza() {
      window.open('https://mezzobar.com.br/media/sobremezas.pdf', '_blank');
    },

    downloadVinhos() {
      window.open('https://mezzobar.com.br/media/vinhos.pdf', '_blank');
    },

    downloadCardapioExe() {
      window.open('https://mezzobar.com.br/media/pratos.pdf', '_blank');
    },

    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/clubemezzo');
    }
  },

  watch: {
    group() {
      this.drawer = false;
    }
  }
};
</script>

