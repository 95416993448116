<template>
  <v-row class="py-8 ma-0 pt-0 pb-0 mb-0">
    <v-col cols="12" md="12" class="d-flex align-center justify-center" style="gap: 80px; background-color: black"
      v-if="intBreakPoint >= 2">
      <img src="../../assets/lpAniversario2anos/mezzo.webp" width="20%" alt="logo Mezzo" />
      <p class="d-flex align-center justify-center" style="color: #fff; font-size: 18px; font-weight: 700">
        Sua página de eventos e novidades do Mezzo Bar.
      </p>
    </v-col>

    <v-col cols="12" md="12" class="d-flex align-center flex-column justify-center"
      style="gap: 30px; background-color: black" v-if="intBreakPoint < 2">
      <img src="../../assets/lpAniversario2anos/mezzo.webp" width="80%" alt="logo Mezzo" />
      <p class="d-flex align-center justify-center" style="
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          text-align: center;
        ">
        Sua página de eventos e novidades do Mezzo Bar.
      </p>
    </v-col>

    <a class="banner pb-12"
      href="https://api.whatsapp.com/send?phone=5512996307396&text=Ol%C3%A1,%20vim%20pelo%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
      target="_blank" v-if="intBreakPoint >= 2">
      <img src="../../assets/lpAniversario2anos/baaner.webp" alt="fundo evento">
    </a>

    <a class="banner"
      href="https://api.whatsapp.com/send?phone=5512996307396&text=Ol%C3%A1,%20vim%20pelo%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
      target="_blank" v-if="intBreakPoint < 2"><img src="../../assets/lpAniversario2anos/baanerm.webp" style="width: 100%"
        alt="fundo evento" /></a>

    <!-- <v-col cols="12" class="d-flex flex-column px-0" style="min-height: 500px" v-if="intBreakPoint < 2">
      <img src="../../assets/lpAniversario2anos/proximoseventos.webp" style="width: 100%; padding-left: 10px"
        alt="logo Mezzo" />
      <a href="https://api.whatsapp.com/send?phone=5512997714387&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20o%20restaurante."
        target="_blank"><img src="../../assets/lpAniversario2anos/17dez.webp"
          style="width: 100%; border-radius: 30px; padding: 10px" alt="logo Mezzo" /></a>
    </v-col> -->

    <!-- <v-col cols="12" class="pl-0 ma-0 pa-0 d-flex" style="gap: 50px" v-if="intBreakPoint >= 2">
      <img src="../../assets/lpAniversario2anos/pro.webp" style="width: 25%" />
      <a href="https://api.whatsapp.com/send?phone=5512997714387&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20o%20restaurante."
        target="_blank" alt="evento"><img src="../../assets/lpAniversario2anos/17dez.webp"
          style="width: 55%; border-radius: 30px" alt="evento"/></a>
    </v-col> -->

    <v-col cols="12" md="12" class="px-0" style="padding-top: 20px" v-if="intBreakPoint >= 2">
      <img src="../../assets/lpAniversario2anos/pratoss.webp" width="100%" alt="pratos"/>
    </v-col>
    <v-col cols="12" md="12" class="px-0" v-if="intBreakPoint < 2">
      <img src="../../assets/lpAniversario2anos/33.webp" style="width: 100%; padding-left: 8px; padding-right: 8px" alt="pratos"/>
      <img src="../../assets/lpAniversario2anos/666.webp" style="width: 100%; padding-left: 8px; padding-right: 8px" alt="pratos"/>
      <!-- <img src="../../assets/lpAniversario2anos/novospratos.webp"
        style="width: 100%; padding-left: 8px;" /> -->
    </v-col>

    <v-col cols="12" md="12" class="d-flex align-center justify-center" style="
        background-color: #000;
        gap: 80px;
        align-items: center;
        padding-top: 20px;
      " v-if="intBreakPoint >= 2">
      <h2 class="exo" style="
          color: #fff;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.2px;
        ">
        Clube Mezzo
      </h2>
      <p class="exo" style="
          color: #fff;
          font-size: 22px;
          font-weight: 600;
          justify-content: center;
          align-items: center;
          text-align: center;
        ">
        Faça parte do Clube Mezzo e tenha 10% de desconto no almoço executivo<br />
        e acumule 10% de cashback para usar quando quiser
      </p>
    </v-col>
    <v-col cols="12" md="12" class="d-flex align-center flex-column justify-center" style="
        background-color: #000;
        gap: 20px;
        align-items: center;
        padding-top: 20px;
      " v-if="intBreakPoint < 2">
      <h2 class="exo" style="
          color: #fff;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.2px;
        ">
        Clube Mezzo
      </h2>
      <p class="exo" style="
          color: #fff;
          font-size: 22px;
          font-weight: 600;
          justify-content: center;
          align-items: center;
          text-align: center;
        ">
        Faça parte do Clube Mezzo e tenha 10% de desconto no almoço executivo e
        acumule 10% de cashback para usar quando quiser
      </p>
    </v-col>
    <v-col cols="12" md="12" class="d-flex align-center justify-center"
      style="background-color: #000; align-items: center" v-if="intBreakPoint >= 2">
      <img src="../../assets/lpAniversario2anos/elementos.webp" style="padding-top: 20px; padding-bottom: 10px" alt="elementos"/>
    </v-col>
    <v-col cols="12" md="12" class="d-flex flex-column align-center justify-center"
      style="background-color: #000; align-items: center" v-if="intBreakPoint < 2">
      <img src="../../assets/lpAniversario2anos/1.webp" style="padding-top: 20px; width: 80%" />
      <img src="../../assets/lpAniversario2anos/3.webp" style="padding-top: 40px; padding-bottom: 10px; width: 80%" alt="elementos"/>
      <img src="../../assets/lpAniversario2anos/4.webp" style="padding-top: 40px; padding-bottom: 10px; width: 70%" alt="elementos"/>
      <img src="../../assets/lpAniversario2anos/5.webp" style="padding-top: 20px; padding-bottom: 10px; width: 80%" alt="elementos"/>
      <img src="../../assets/lpAniversario2anos/6.webp" style="padding-top: 40px; padding-bottom: 10px; width: 80%" alt="elementos"/>
      <img src="../../assets/lpAniversario2anos/7.webp" style="padding-top: 0px; padding-bottom: 20px; width: 70%" alt="elementos"/>
    </v-col>

    <v-col style="height: 40px; background-color: #931f41"></v-col>

    <v-col cols="12" md="12" class="exo d-flex flex-column align-center justify-center" style="
        background-color: #000;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
      ">
      <h2 class="exo" style="
          color: #fff;
          font-size: 36px;
          font-weight: 700;
          letter-spacing: 0.2px;
          padding-bottom: 40px;
        " v-if="intBreakPoint >= 2">
        Como participar do Clube Mezzo
      </h2>
      <h2 class="exo" style="
          color: #fff;
          font-size: 30px;
          font-weight: 700;
          letter-spacing: 0.2px;
          padding-bottom: 10px;
          display: flex;
          align-items: center;
          text-align: center;
        " v-if="intBreakPoint < 2">
        Como participar do Clube Mezzo
      </h2>
      <img src="../../assets/lpAniversario2anos/4passos.webp" alt="4 passos" v-if="intBreakPoint >= 2" />
      <img src="../../assets/lpAniversario2anos/12.webp" alt="passos" style="width: 100%; padding-top: 20px"
        v-if="intBreakPoint < 2" />
      <img src="../../assets/lpAniversario2anos/34.webp" alt="passos" style="width: 100%; padding-top: 20px"
        v-if="intBreakPoint < 2" />
    </v-col>

    <v-col cols="12" md="12" class="banner2 d-flex flex-column align-center justify-center">
      <h2 class="exo" style="
          color: #fff;
          text-align: center;
          font-family: Exo;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.2px;
        " v-if="intBreakPoint >= 2">
        Faça seu evento corporativo conosco!
      </h2>
      <h2 class="exo" style="
          color: #fff;
          text-align: center;
          font-family: Exo;
          font-size: 35px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.2px;
          padding-bottom: 20px;
        " v-if="intBreakPoint < 2">
        Faça seu evento corporativo conosco!
      </h2>
      <p class="exo" style="
          color: #fff;
          text-align: center;
          font-family: Exo;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.2px;
          padding-bottom: 20px;
        ">
        Onde o refinamento encontra o prazer de aproveitar a vida e colher
        exclusividades.
      </p>
      <a href="https://mezzobar.com.br/clubemezzo" target="_blank"><button class="exo" style="
            color: #000;
            text-align: center;
            font-family: Exo;
            font-weight: 900;
            background-color: #fff;
            width: 246px;
            height: 48px;
            border-radius: 10px;
          ">
          Faça parte do Club Mezzo
        </button></a>
    </v-col>

    <v-col cols="12" md="12" class="d-flex align-center justify-center" style="background-color: #000"
      v-if="intBreakPoint >= 2">
      <v-col cols="4" class="d-flex flex-column align-center justify-center">
        <a href="https://mezzobar.com.br/" target="_blank"><img src="../../assets/lpAniversario2anos/mezzo2.webp"
            style="width: 131px; height: 69px" alt="logo Mezzo" /></a>
        <div class="d-flex align-center justify-space-around">
          <a href="https://www.facebook.com/mezzobarsjc/" target="_blank"><img
              src="../../assets/lpAniversario2anos/facebook.webp" style="margin-right: 20px" alt="facebook" /></a>
          <a href="https://www.instagram.com/mezzobarsjc/" target="_blank"><img
              src="../../assets/lpAniversario2anos/insta.webp" alt="facebook"/></a>
        </div>
      </v-col>
      <v-col cols="4" class="d-flex align-center justify-center">
        <a href="https://api.whatsapp.com/send?phone=5512996307396&text=Ol%C3%A1,%20vim%20pelo%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
          target="_blank"><button class="d-flex align-center justify-center" style="
              background-color: #169842;
              color: #fff;
              width: 256px;
              height: 60px;
              border-radius: 20px;
            ">
            Chamar no WhatsApp
          </button></a>
      </v-col>
      <v-col cols="4" class="d-flex flex-column align-center justify-center" style="gap: 20px">
        <a href="https://abaregroup.com/" target="_blank"><img src="../../assets/lpAniversario2anos/abare.webp"
            alt="logo Abaré" /></a>
        <button @click="openPopup = true" class="d-flex flex-column align-center justify-center" style="
            width: 203px;
            height: 60px;
            color: #fff;
            background-color: #261d4d;
            border-radius: 20px;
          ">
          Política de privacidade
        </button>
        <v-dialog v-model="openPopup" :width="mdAndUp ? '50vw' : '95vw'">
          <PrivacyPolicy @closeDialog="openPopup = false" />
        </v-dialog>
      </v-col>
    </v-col>
    <v-col cols="12" md="12" class="d-flex flex-column align-center justify-center my-0"
      style="background-color: #000; min-height: 650px" v-if="intBreakPoint < 2">
      <v-col cols="4" class="d-flex flex-column align-center justify-center pt-12">
        <a href="https://mezzobar.com.br/" target="_blank"><img src="../../assets/lpAniversario2anos/mezzo2.webp"
            style="width: 131px; height: 69px" alt="logo Mezzo" /></a>
        <div class="d-flex align-center justify-space-around">
          <a href="https://www.facebook.com/mezzobarsjc/" target="_blank"><img
              src="../../assets/lpAniversario2anos/facebook.webp" style="margin-right: 20px" alt="facebook" /></a>
          <a href="https://www.instagram.com/mezzobarsjc/" target="_blank"><img
              src="../../assets/lpAniversario2anos/insta.webp" alt="instagram"/></a>
        </div>
      </v-col>
      <v-col cols="4" class="d-flex align-center justify-center pt-0">
        <a href="https://api.whatsapp.com/send?phone=5512996307396&text=Ol%C3%A1,%20vim%20pelo%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es."
          target="_blank"><button class="d-flex align-center justify-center" style="
              background-color: #169842;
              color: #fff;
              width: 240px;
              height: 60px;
              border-radius: 20px;
            ">
            Chamar no WhatsApp
          </button></a>
      </v-col>
      <v-col cols="4" class="d-flex flex-column align-center justify-center mb-0" style="gap: 20px; padding-bottom: 40px">
        <a href="https://abaregroup.com/" target="_blank"><img src="../../assets/lpAniversario2anos/abare.webp"
            alt="logo Abaré" /></a>
        <button @click="openPopup = true" class="d-flex flex-column align-center justify-center" style="
            width: 203px;
            height: 60px;
            color: #fff;
            background-color: #261d4d;
            border-radius: 20px;
          ">
          Política de privacidade
        </button>
        <v-dialog v-model="openPopup" :width="mdAndUp ? '50vw' : '95vw'">
          <PrivacyPolicy @closeDialog="openPopup = false" />
        </v-dialog>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import PrivacyPolicy from '../../components/PrivacyPolicy.vue';

export default {
  name: 'Aniversário 2 anos',
  components: { PrivacyPolicy },
  data() {
    return {
      openPopup: false
    };
  },
  computed: {
    intBreakPoint() {
      const breakpoints = {
        xs: 0,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 4
      };
      return breakpoints[this.$vuetify.breakpoint.name || 'xs'];
    }
  },
  metaInfo: {
    // título da página
    title: 'Eventos',
    // meta tags
    meta: [
      {
        vmid: 'eventos',
        name: 'eventos',
        content: 'Realize seu evento corporatio no mezanino mais elegante do Jardim Aquarius.'
      }
    ]
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Basic&family=Inter:wght@400;700&family=PT+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Basic&family=Exo&family=Inter:wght@400;700&family=PT+Sans&display=swap');
@import url('/font-awesome-4.6.3/css/font-awesome.min.css');

* {
  font-family: 'Inter', sans-serif;
}

.exo {
  font-family: 'Exo', sans-serif;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* Para centralizar a imagem no IE 11 */
}

.banner img {
  max-width: 100%;
  /* Para garantir que a imagem não ultrapasse o contêiner */
  height: auto;
  /* Para manter a proporção da imagem */
}

.banner4 {
  background-image: url('../../assets/lpAniversario2anos/almoco.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner2 {
  background-image: url('../../assets/lpAniversario2anos/banner3.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 420px;
}

li {
  opacity: 0.5;
  color: #000;
  font-family: Exo;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.whatsapp-link {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 45px;
  box-shadow: 2px 2px 3px #888;
  z-index: 1000;
  transition: all 0.4s ease;
}

@media screen and (max-width: 950px) {
  .whatsapp-link {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 30px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 45px;
    box-shadow: 2px 2px 3px #888;
    z-index: 1000;
  }
}

.whatsapp-link:hover {
  opacity: 0.8;
}

#btnwpp {
  margin-top: 11px;
}

.btn {
  color: #fff;
  text-align: center;
  font-family: Exo;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  background-color: #151111;
  height: 70px;
  position: absolute;
  left: 0;
  width: 260px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  bottom: 1;
}

.btn2 {
  color: #fff;
  text-align: center;
  font-family: Exo;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  background-color: #151111;
  height: 60px;

  width: 240px;
  border-radius: 30px;
}
</style>
