import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueTour from 'vue-tour';
import VueMask from 'v-mask';
import axios from 'axios';
import store from './store';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Notifications from 'vue-notification';

Vue.component(VueCountdown.name, VueCountdown);

Vue.use(VueTour);
Vue.use(Notifications);

Vue.config.productionTip = false;
Vue.use(VueMask);

Vue.filter('formatISODate', function (value) {
  if (value) {
    let dtTime = value.split('T');
    let dt = dtTime[0].split('-');
    let time = dtTime[1]
      ? dtTime[1].split('-')[0].replace('.000Z', '')
      : '00:00:00';
    return dt[2] + '/' + dt[1] + '/' + dt[0] + ' ' + time;
  }
});

Vue.filter('formatISODateUtc', function (value) {
  if (value) {
    let date = new Date(value);

    // Subtrai 3 horas da data
    date.setHours(date.getHours() - 3);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    let formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
    let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return `${formattedDate} ${formattedTime}`;
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');

// const token = store.state.token;
// if (token) {
//   axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
// }

const token = store.state.token;
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
axios.defaults.baseURL = 'https://mezzo.abare.cloud';
// axios.defaults.baseURL = 'http://localhost:8055';
// axios.defaults.baseURL = 'http://192.168.15.50:8060';

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log(error);
    if (error.response.status !== 401) {
      return Promise.reject(error);
    } else if (
      error.response.data.errors[0].message === 'Invalid user credentials.'
    ) {
      error.response.data.errors[0].message = 'Usuário ou senha inválidos';
      // alert('Usuário ou senha inválidos');
      return Promise.reject(error);
    } else if (error.response.data.errors[0].message === 'User suspended.') {
      // alert('Usuário suspenso');
      error.response.data.errors[0].message = 'Usuário supenso';
      return Promise.reject(error);
    } else if (
      error.response.status === 401 &&
      error.config.url === '/auth/refresh'
    ) {
      await store.dispatch('logout');
      return Promise.reject(error);
    } else if (error.response.status === 401) {
      await store.dispatch('refreshToken');
      if (store.state.token) {
        error.config.headers.Authorization = 'Bearer ' + store.state.token;
        return axios.request(error.config);
      } else {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);
