import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store.js';
import aniversario2anos from '../views/LPs_Novas/aniversario2anos.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Cardapio',
    name: 'Cardapio',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Cardapio.vue')
  },
  {
    path: '/localizacao',
    name: 'localizacao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/localizacao.vue')
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Cadastro.vue')
  },
  {
    path: '/copa',
    name: 'lpCopa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/lpCopa.vue')
  },
  {
    path: '/natal',
    name: 'lpNatal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/lpNatal.vue')
  },
  {
    path: '/show-nps',
    name: 'ShowNPSMembers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ShowNPSMembers.vue')
  },
  {
    path: '/show-members',
    name: 'ShowMembers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ShowMembers.vue'),
    beforeEnter(to, from, next) {
      if (store.getters.isLoggedIn) {
        next();
      } else {
        next({
          name: 'login' // back to safety route //
        });
      }
    }
  },
  {
    path: '/clubemezzo',
    name: 'Clube Mezzo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/clubeMezzo.vue')
  },
  {
    path: '/empresas-parceiras',
    name: 'empresas-parceiras',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/empresas-parceiras.vue')
  },
  {
    path: '/lojas',
    name: 'lojas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/lojas.vue')
  },
  // {
  //   path: '/mezzo-coin',
  //   name: 'MezzoCoin',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/MezzoCoin.vue')
  // },
  {
    path: '/harmonizza',
    name: 'Harmonizza',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/harmonizza.vue')
  },
  {
    path: '/cpf',
    name: 'cpf',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/userCPF.vue')
  },
  {
    path: '/cadastro-senha/:token?',
    name: 'cadastroSenha',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/cadastroSenha.vue')
  },
  {
    path: '/propagandistas',
    name: 'Propagandistas',
    component: () => import('../views/LPs_Novas/PropagandistasView.vue')
  },
  // {
  //   path: '/cadastroSenha',
  //   name: 'cadastroSenha',
  //   component: () => import('../views/cadastroSenha.vue')
  // },
  {
    path: '/panel',
    name: 'Cashback',
    component: () => import('../views/PanelCashback.vue'),
    meta: {
      requiresFullAccess: true
    }
  },
  {
    path: '/perfil',
    name: 'appUser',
    component: () => import('../views/appUser.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/eventos',
    name: 'Aniversário 2 anos',
    component: aniversario2anos
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresFullAccess)) {
    if (sessionStorage.getItem('fullAccess')) {
      return next();
    }
    if (store.state.fullAccess === true) {
      return next();
    } else {
      next('/clubemezzo');
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.isLoggedIn === true) {
      next();
    } else {
      next('/clubemezzo');
    }
  } else {
    next();
  }
});

export default router;

