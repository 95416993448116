import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cart: {
      name: '',
      add: [],
      items: []
    },
    status: '',
    isLoggedIn: null,
    fullAccess: null,
    token: localStorage.getItem('token') || null,
    rtoken: localStorage.getItem('rtoken') || null,
    user: null,
  },
  mutations: {
    addToCart(state, product) {
      state.cart.items.push(product);
    },
    SET_ISLOGGEDIN(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_RTOKEN(state, rtoken) {
      state.rtoken = rtoken;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_FULLACCESS(state, fullAccess) {
      state.fullAccess = fullAccess;
    }
  },
  getters: {
    totalItems(state) {
      return state.cart.items.length;
    }
  },
  actions: {
    isLoogedIn(context) {
      context.commit('isLoogedIn');
    },
    async login({ commit }, user) {
      try {
        const {
          data: { data }
        } = await axios.post(
          `/auth/login`,
          {
            email: user.email,
            password: user.password
          },
          {
            headers: {
              Authorization: null
            }
          }
        );

        if (data) {
          const token = data.access_token;
          const refreshToken = data.refresh_token;
          localStorage.setItem('token', token);
          localStorage.setItem('rtoken', refreshToken);
          commit('SET_TOKEN', token);
          commit('SET_RTOKEN', refreshToken);

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          console.log(token);
          await this.dispatch('getUserInfo');
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.errors[0]?.message ?? error.message;
        console.log('login error', errorMessage);
        commit('SET_ISLOGGEDIN', false);
        throw new Error(errorMessage);
      }
    },
    async logout({ commit }) {
      commit('SET_TOKEN', null);
      commit('SET_RTOKEN', null);
      commit('SET_USER', null);
      commit('SET_ISLOGGEDIN', null);
      commit('SET_FULLACCESS', null);

      localStorage.removeItem('token');
      localStorage.removeItem('rtoken');
      localStorage.removeItem('user');
      delete axios.defaults.headers.common['Authorization'];
    },
    async recoverPassword(_, email) {
      try {
        const {
          data: { data }
        } = await axios.post(`/auth/password/request`, {
          email
        });

        console.log(data);
      } catch (error) {
        console.log('recover password error', error);
        throw new Error(error);
      }
    },
    async getUserInfo({ commit }) {
      try {
        const {
          data: { data }
        } = await axios.get(
          `/users/me?fields=avatar,balance,birthday,company,description,document,email,first_name,last_name,id,phone,role,transactions`
        );

        if (data) {
          commit('SET_USER', data);
          commit('SET_ISLOGGEDIN', true);
          localStorage.setItem('user', JSON.stringify(data));
          if (
            data.role === '737cb10e-c12a-4657-895e-fe385852e1b5' ||
            data.role === '915297b5-320d-4828-9a5e-fe348c61347b'
          ) {
            commit('SET_FULLACCESS', true);
          } else if (data.role === '10cacf12-2e2c-416d-8d52-63baaea8baea') {
            commit('SET_FULLACCESS', false);
          }
        }
      } catch (error) {
        console.log('get info error', error);
        return new Error(error);
      }
    },
    async refreshToken({ commit, state }) {
      try {
        if (state.rtoken !== null) {
          commit('SET_TOKEN', null);
          const {
            data: { data }
          } = await axios.post(
            `/auth/refresh`,
            { refresh_token: state.rtoken },
            { headers: { Authorization: null } }
          );

          if (data) {
            const token = data.access_token;
            const refreshToken = data.refresh_token;
            localStorage.setItem('token', token);
            localStorage.setItem('rtoken', refreshToken);
            commit('SET_TOKEN', token);
            commit('SET_RTOKEN', refreshToken);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          }
        }
      } catch (error) {
        console.log('refresh token error', error);
        throw new Error(error);
      }
    }
  }
});

